<template>
    <div class="card-info">
        <h2>{{ $t(`ProductionProgress.InfoTab.${title}.Title`) }}</h2>
        <div
            :class="[
                'infos-container',
                { 'order-card': title === 'Order' },
                { 'operation-card': title === 'Operation' },
                { 'attributes-card': title === 'Attributes' },
                { 'observation-card': title === 'Observation' },
            ]"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: 'Order' | 'Operation' | 'Attributes' | 'Observations',
            default: 'Order',
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.card-info {
    padding: 24px 0 23px;
    border-bottom: 1px solid #CFC4BE;

    &:last-child {
        padding: 24px 0;
        border-bottom: none;
    }

    h2 {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #4C4541;
        margin: 0;
    }

    .infos-container {
        margin-top: 16px;
        gap: 16px 30px;
        display: grid;

        &.order-card {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &.operation-card {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &.attributes-card {
            grid-auto-flow: column;
            grid-template-columns: repeat(3, minmax(0, 1fr));

            .info-box-container:nth-child(1) {
                grid-row: 1;
            }
            .info-box-container:nth-child(2) {
                grid-row: 2;
            }
            .info-box-container:nth-child(3) {
                grid-row: 3;
            }
            .info-box-container:nth-child(4) {
                grid-row: 4;
            }
            .info-box-container:nth-child(5) {
                grid-row: 5;
            }
        }

        &.observation-card {
            grid-template-rows: minmax(0, 1fr);
        }
    }

    .observation {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998F8A;
    }
}

@media (max-width: 768px) and (min-width: 600px) {
    .card-info {
        .infos-container {
            &.order-card {
                grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
            }
        }
    }
}

@media (max-width: 600px) {
    .card-info {
        .infos-container {
            gap: 8px 16px !important;
            &.order-card {
                grid-template-columns: minmax(0, 1fr) !important;
            }

            &.operation-card {
                grid-template-columns: minmax(0, 1fr) !important;
            }

            &.attributes-card {
                grid-auto-flow: row !important;
                grid-template-columns: minmax(0, 1fr) !important;
                grid-template-rows: inherit !important;
            }
        }
    }

}


@media (max-width: 425px) {
    .card-info {
        .infos-container {
            gap: 8px !important;
        }

        h2 {
            font-size: 18px !important;
            line-height: 26px !important;
        }

        .observation {
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
}
</style>
