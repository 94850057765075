<template>
    <div class="info-tab-container">
        <InfoCard
            v-for="[data, dataValue], dataIndex in computedData"
            :key="`data-${dataIndex}`"
            :title="data"
        >
            <InfoBox
                v-for="[key, keyValue], keyIndex in Object.entries(dataValue)"
                :card="data"
                :key="`order-${keyIndex}`"
                :title="key"
                :isDate="isDateString(keyValue.value)"
                :data="keyValue"
            />
        </InfoCard>

        <InfoCard title="Observations">
            <div class="observation" v-html="data.Observations"/>
        </InfoCard>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import InfoCard from './components/InfoCard';
import InfoBox from './components/InfoBox';

export default {
    components: {
        InfoCard,
        InfoBox
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedData() {
            const array = Object.entries(this.data);
            array.pop();

            return array;
        }
    },
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        isDateString(string) {
            if(!string) return false;

            const regex = /^(\d{4})([-./])(\d{2})\2(\d{2})$/;
            return regex.test(string.split(' ')[0]);
        }
    }
}
</script>

<style lang="scss" scoped>
.info-tab-container {
    flex-grow: 1;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    padding: 0 29px;
    width: 100%;

    .observation {
        max-width: calc(100vw - 29px - 29px);
    }
}

@media (max-width: 425px) {
    .info-tab-container {
        padding: 0 16px !important;
    }

    .observation {
        max-width: calc(100vw - 16px - 16px) !important;
    }
}
</style>
